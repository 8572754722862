<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">人员管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">市场人员</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div title="姓名" class="searchboxItem ci-full">
              <span class="itemLabel">姓名:</span>
              <el-input
                v-model="searchName"
                type="text"
                clearable
                size="small"
                placeholder="请输入姓名"
              />
            </div>
            <div title="手机号" class="searchboxItem ci-full">
              <span class="itemLabel">手机号:</span>
              <el-input
                v-model="searchPhone"
                type="text"
                clearable
                size="small"
                placeholder="请输入手机号"
              />
            </div>
            <el-button class="bgc-bv" round @click="getData()">查询</el-button>
          </div>
          <!-- <div class="df ci-full">
            <el-button class="bgc-bv" round @click="add()">新增职工</el-button>
            <el-button class="bgc-bv" round @click="Exportcomp">导入职工</el-button>
          </div>-->
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              stripe
              :header-cell-style="tableHeader"
            >
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" />
              <el-table-column label="姓名" align="center" show-overflow-tooltip prop="fullname" />
              <el-table-column label="手机号" align="center" show-overflow-tooltip prop="mobile" />
              <el-table-column
                label="负责机构数量"
                align="center"
                show-overflow-tooltip
                prop="compCount"
              />
              <el-table-column label="操作" align="center" width="200px">
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    style="padding:0px 15px"
                    type="text"
                    size="mini"
                    @click="handle(scope.row.adminId)"
                  >负责机构</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
    <el-dialog
      title="关联机构"
      :visible.sync="centerDialogVisible"
      width="80%"
      center
      :before-close="doCloseload"
      class="relation_jg_dialog"
    >
      <div class="flexcc">
        <div class="left table_box">
          <div class="flexca table_box_top">
            <span>市场负责人：</span>
            <el-select
              clearable
              size="small"
              v-model="dialogLeftMarketUser"
              placeholder="请选择"
              @change="changeLeftData"
            >
              <el-option
                v-for="item in options"
                :key="item.adminId"
                :label="item.fullname"
                :value="item.adminId"
                :disabled="item.adminId == dialogRightMarketUser"
              ></el-option>
            </el-select>
          </div>
          <div>
            <div class="list_top">
              <p>待分配机构({{leftDataAll.length}}/{{apiDataLeft.total}})</p>
            </div>
            <div class="list_search flexca">
              <el-input
                size="small"
                placeholder="请输入机构名称"
                v-model="jgNameLeft"
                style="margin-right:10px;width:40%"
                clearable
              ></el-input>
              <el-cascader
                clearable
                filterable
                :props="{emitPath:false,checkStrictly: true}"
                v-model="areaLeft"
                :options="cityTree"
                @change="handleCascader"
                size="small"
                style="margin-right:10px;width:40%"
              ></el-cascader>
              <el-button size="small" class="bgc-bv" @click="getDataLeft()">查询</el-button>
            </div>
            <div class="list_table">
              <el-table
                ref="leftMultipleTable"
                :data="leftData"
                tooltip-effect="dark"
                style="width: 100%;margin-bottom:10px"
                size="small"
                border
                height="300px"
                row-key="compId"
                @selection-change="handleSelectionChangeLeft"
              >
                <el-table-column
                  type="selection"
                  width="40"
                  align="center"
                  :reserve-selection="selection"
                ></el-table-column>
                <el-table-column prop="compName" align="left" label="机构名称" show-overflow-tooltip></el-table-column>
                <el-table-column
                  prop="areaNamePath"
                  align="center"
                  label="行政区划"
                  show-overflow-tooltip
                  width="200"
                ></el-table-column>
                <Empty slot="empty" />
              </el-table>
              <el-pagination
                @size-change="leftSizeChange"
                @current-change="getDataLeft"
                :current-page="apiDataLeft.current"
                :pager-count="5"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="Math.ceil(apiDataLeft.size)"
                layout="total, sizes, prev, pager, next"
                :total="parseInt(apiDataLeft.total)"
                background
                style="margin-bottom:10px"
                small
                class="relation_page"
              ></el-pagination>
            </div>
          </div>
        </div>

        <!--  -->
        <div class="btn_arrow flex1">
          <div>
            <el-button class="bgc-bv" icon="el-icon-arrow-left" @click="btnToLeft"></el-button>
            <el-button class="bgc-bv" icon="el-icon-arrow-right" @click="btnToRight"></el-button>
          </div>
        </div>
        <!-- right -->
        <div class="right table_box">
          <div class="flexca table_box_top">
            <span>市场负责人：</span>
            <el-select
              disabled
              size="small"
              v-model="dialogRightMarketUser"
              placeholder="请选择"
              clearable
            >
              <el-option
                v-for="item in marketLeaderArr"
                :key="item.adminId"
                :label="item.fullname"
                :value="item.adminId"
              ></el-option>
            </el-select>
          </div>
          <div>
            <div class="list_top">
              <p>{{getuser}}负责机构({{rightDataAll.length}}/{{apiDataRight.total}})</p>
            </div>
            <div class="list_search flexca">
              <el-input
                size="small"
                placeholder="请输入机构名称"
                v-model="jgNameRight"
                style="margin-right:10px;width:40%"
                clearable
              ></el-input>
              <el-cascader
                clearable
                filterable
                :props="{emitPath:false,checkStrictly: true}"
                v-model="areaRight"
                :options="cityTree"
                @change="handleCascader"
                size="small"
                style="margin-right:10px;width:40%"
              ></el-cascader>
              <el-button size="small" class="bgc-bv" @click="getDataRight()">查询</el-button>
            </div>
            <div class="list_table">
              <el-table
                ref="rightMultipleTable"
                :data="rightData"
                tooltip-effect="dark"
                style="width: 100%;margin-bottom:10px"
                size="small"
                row-key="compId"
                border
                height="300px"
                @selection-change="handleSelectionChangeRight"
              >
                <el-table-column
                  type="selection"
                  width="40"
                  align="center"
                  :reserve-selection="selection"
                ></el-table-column>
                <el-table-column prop="compName" align="left" label="机构名称" show-overflow-tooltip></el-table-column>
                <el-table-column
                  prop="areaNamePath"
                  align="center"
                  label="行政区划"
                  show-overflow-tooltip
                  width="200"
                ></el-table-column>
                <Empty slot="empty" />
              </el-table>
              <el-pagination
                @size-change="rightSizeChange"
                @current-change="getDataRight"
                :current-page="apiDataRight.current"
                :pager-count="5"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="Math.ceil(apiDataRight.size)"
                layout="total, sizes, prev, pager, next"
                :total="parseInt(apiDataRight.total)"
                background
                style="margin-bottom:10px"
                small
                class="relation_page"
              ></el-pagination>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "marketUserList",
  components: {
    Empty,
    PageNum
  },
  mixins: [List],
  data() {
    return {
      selection: true,

      searchPhone: "",
      searchName: "",
      //
      centerDialogVisible: false, //
      dialogLeftMarketUser: "", // 左侧市场负责人
      jgNameLeft: "", // 左侧机构名称
      areaLeft: "", // 左侧行政区域
      leftData: [], // 左侧table
      leftDataAll: [], // 左侧table 全部
      apiDataLeft: {
        total: 0,
        size: 10,
        current: 1
      }, //
      pageNumL: 1,
      pageSizeL: 10,
      //////////
      dialogRightMarketUser: "", // 右侧市场负责人
      jgNameRight: "", // 右侧机构名称
      areaRight: "", // 右侧行政区域
      rightData: [], // 右侧table
      rightDataAll: [], // 右侧table 全部
      apiDataRight: {
        total: 0,
        size: 10,
        current: 1
      }, //
      pageNumR: 1,
      pageSizeR: 10,
      sessionObj: {},
      //
      marketLeaderArr: [], //市场负责人下拉框数组对象
      options: [],
      cityTree: [] // 区划
    };
  },
  computed: {
    getuser() {
      let user = "";
      if (this.marketLeaderArr.length) {
        user = this.marketLeaderArr.find(
          el => el.adminId == this.dialogRightMarketUser
        ).fullname;
      }
      return user;
    }
  },
  mounted() {},
  created() {
    this.leftData = [];
    this.rightData = [];
  },

  methods: {
    doCloseload() {
      this.centerDialogVisible = false;
      this.leftDataAll = [];
      this.rightDataAll = [];
      this.clearSelection();
    },
    clearSelection() {
      this.$refs.rightMultipleTable.clearSelection();
      this.$refs.leftMultipleTable.clearSelection();
    },
    // 数组去重
    doRepeat(arr) {
      if (!Array.isArray(arr)) {
        return;
      }
      let checkArr = [];
      let newArr = [];
      for (let i = 0; i < arr.length; i++) {
        if (!checkArr.includes(arr[i].compId)) {
          checkArr.push(arr[i].compId);
          newArr.push(arr[i]);
        }
      }
      this.leftCheckLength = newArr.length;
      return newArr;
    },
    /**
     * 弹窗左侧
     */
    //复选框事件
    handleSelectionChangeLeft(val) {
      this.leftDataAll = val;
      console.log(123);
    },
    // 左侧点击分页
    getDataLeft(pageNum = 1) {
      // 取当前页得数组
      this.pageNumL = pageNum;
      let params = {
        pageNum,
        pageSize: this.pageSizeL,
        adminId: this.dialogLeftMarketUser,
        compName: this.jgNameLeft || "",
        areaId: this.areaLeft || ""
      };
      this.$post("/sys/company/adminId", params)
        .then(res => {
          if (res.status == "0") {
            const data = res.data || {};
            this.leftData = data.list;
            this.apiDataLeft = {
              total: data.total,
              size: this.pageSizeL,
              current: this.pageNumL || 1
            };
            // let leftDataAllList = this.leftDataAll || [];
            // if (leftDataAllList.length) {
            //   this.$nextTick(() => {
            //     (leftDataAllList || []).forEach(el => {
            //       this.leftData.forEach((it, index) => {
            //         if (it.compId == el.compId) {
            //           this.$refs.leftMultipleTable.toggleRowSelection(it, true);
            //         }
            //       });
            //     });
            //   });
            // }
          }
        })
        .catch();
    },
    // 左侧市场负责人改变调接口
    changeLeftData() {
      this.getDataLeft();
    },
    // 加载条数
    leftSizeChange(pageSize) {
      this.pageSizeL = pageSize;
      this.getDataLeft();
    },
    /**
     * 弹窗右侧
     */
    handleSelectionChangeRight(val) {
      this.rightDataAll = val;
    },
    rightSizeChange(pageSize) {
      this.pageSizeR = pageSize;
      this.getDataRight();
    },
    // 右侧点击分页
    getDataRight(pageNum = 1) {
      this.pageNumR = pageNum;
      let params = {
        pageNum,
        pageSize: this.pageSizeR,
        adminId: this.dialogRightMarketUser,
        compName: this.jgNameRight || "",
        areaId: this.areaRight || ""
      };
      this.$post("/sys/company/adminId", params)
        .then(res => {
          const data = res.data || {};
          this.rightData = data.list;
          this.apiDataRight = {
            total: data.total,
            size: this.pageSizeR,
            current: this.pageNumR || 1
          };

          // let rightDataAllList = this.rightDataAll || [];
          // if (rightDataAllList.length) {
          //   this.$nextTick(() => {
          //     (rightDataAllList || []).forEach(el => {
          //       this.rightData.forEach((it, index) => {
          //         if (it.compId == el.compId) {
          //           this.$refs.rightMultipleTable.toggleRowSelection(it, true);
          //         }
          //       });
          //     });
          //   });
          // }
        })
        .catch();
    },
    /**
     * 按钮
     */
    // 点击右箭头
    btnToRight() {
      this.leftDataAll.forEach(el => {
        this.rightData.unshift(el);
        this.leftData = this.leftData.filter(it => it.compId != el.compId);
      });
      this.dosave(this.dialogRightMarketUser, "leftDataAll");
      this.leftDataAll = [];
    },
    // 点击左箭头
    btnToLeft() {
      this.rightDataAll.forEach(el => {
        this.leftData.unshift(el);
        this.rightData = this.rightData.filter(it => it.compId != el.compId);
      });
      this.dosave(this.dialogLeftMarketUser, "rightDataAll");
      this.rightDataAll = [];
    },
    // 分配
    dosave(id, type) {
      const params = this[type].map(el => {
        return {
          compId: el.compId,
          adminId: id
        };
      });
      if (!params.length) {
        this.$message({
          type: "warning",
          message: "请选择需要分配的机构"
        });
        return;
      }
      this.$post("/sys/company/modifyMarket", params).then(res => {
        if (res.status == "0") {
          this.$message({
            type: "success",
            message: "分配成功"
          });
          this.jgNameLeft = "";
          this.areaLeft = "";
          this.jgNameRight = "";
          this.areaRight = "";
          this.getDataLeft();
          this.getDataRight();
          this.getData();
          this.clearSelection();
        }
      });
    },
    //
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        fullname: this.searchName || null,
        mobile: this.searchPhone || null
      };

      this.doFetch({
        url: "/sys/admin/marketPage",
        params,
        pageNum
      });
    },
    //
    handle(id) {
      this.dialogRightMarketUser = id;
      this.getmarketList();
      this.centerDialogVisible = true;
      this.getDataLeft();
      this.getDataRight();
      this.getcity();
    },
    // 市场负责人列表
    getmarketList() {
      this.$post("/sys/admin/market/manager").then(res => {
        if (res.status == "0") {
          this.marketLeaderArr = res.data || [];
          this.options =
            [{ adminId: "", fullname: "待分配" }, ...res.data] || [];
        }
      });
    },
    remoteMethod(query) {
      if (query !== "") {
        setTimeout(() => {
          this.loading = false;
          this.options = this.marketLeaderArr.filter(item => {
            return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
          });
        }, 200);
      } else {
        this.options = this.marketLeaderArr;
      }
    },
    /**
     *  行政区划
     */
    getcity() {
      this.$post("/sys/area/tree")
        .then(res => {
          this.cityTree = res.data || [];
        })
        .catch(() => {
          return;
        });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    }
  },
  beforeRouteLeave: resetKeepAlive
};
</script>
<style lang="less">
.relation_jg_dialog {
  .el-dialog--center .el-dialog__body {
    padding: 25px 20px 30px;
  }
  .el-table--border {
    border-bottom: 1px solid #ebeef5;
    th {
      border-right: 1px solid #ebeef5;
    }
  }
  .table_box {
    width: 42%;
    .table_box_top {
      margin-bottom: 10px;
    }
    // border: 1px red solid;
    .list_top {
      padding: 15px;
      text-align: center;
      border: 1px solid #ebeef5;
      border-bottom: 0;
    }
    .list_search {
      border: 1px solid #ebeef5;
      border-top: 0;
      border-bottom: 0;
      padding: 0 10px 15px;
    }
  }
  // 左右按钮操作
  .btn_arrow {
    padding: 1.25rem;
    > div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .relation_page {
    .el-pagination__sizes {
      .el-select {
        top: -3px;
      }
    }
  }
}
</style>


